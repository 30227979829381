import { render, staticRenderFns } from "./ProductsForm.vue?vue&type=template&id=62590b11&scoped=true"
import script from "./ProductsForm.vue?vue&type=script&lang=js"
export * from "./ProductsForm.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "62590b11",
  null
  
)

export default component.exports